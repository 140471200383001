import {accRestClient} from "../index";


const loadUsers = async (data) => {
  const response = await accRestClient.accountsApi().getUsers("");
  return response.data;
};

const loadMe = async (data) => {
  const response = await accRestClient.accountsApi().getMe();
  return response.data;
};

const updateUserTajneed = async (data) => {
  const response = await accRestClient.accountsApi().addTajneedData(data.userId, data.userData);
  return response.data;
};

const approveUserAccounts = async (data) => {
  const response = await accRestClient.accountsApi().approveUsers(data.users);
  return response.data;
};

const loadActiveUsers = async (event) => {
  const response = await accRestClient.accountsApi().getUsers("amj-account-verified:true");
  return response.data;
};

const loadNotActiveUsers = async (event) => {
  const response = await accRestClient.accountsApi().getUsers("amj-account-verified:false");
  return response.data;
};

const accountRestService = {
  loadUsers,
  updateUserTajneed,
  approveUserAccounts,
  loadMe
};

export default accountRestService;