/* tslint:disable */
/* eslint-disable */
/**
 * AMJ Accounts Service REST API
 * Maintain AMJ Accounts data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export const UserDataDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
};
export const UserDataDtoApprovalStatusEnum = {
    NewRegisteredAccount: 'NEW_REGISTERED_ACCOUNT',
    ApprovalRquested: 'APPROVAL_RQUESTED',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
};
