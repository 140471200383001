/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, {useEffect, useState} from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message, Form, Input, Select, DatePicker, FormProps, Tag, Spin,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined, ExclamationCircleOutlined, CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import {getAuthenticatedUser} from "../utils/authHelper";
import type {AMJ_User} from "../utils/authHelper";
import logo from "../assets/images/logo.svg";
import {I18n, UserDataDto, UserDataDtoGenderEnum} from "acc-rest-client";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedInUser, loadUsers, updateUserTajneed} from "../store/app/app.slice";
import moment from "moment/moment";

function Profile() {
  const dispatch = useDispatch()
  const { loggedInUser} = useSelector((state) => state.app);
  //const amj_user : AMJ_User = getAuthenticatedUser()
  const { countries} = useSelector((state) => state.app);
  const [currentCountry: I18n, setCurrentCountry] = useState(null);
  const [form] = Form.useForm();
  const [componentDisabled, setComponentDisabled] = useState(loggedInUser?.approvalStatus !== "NEW_REGISTERED_ACCOUNT" );
  const [isLoading: boolean, setLoading] = useState(false);

  const onFormValuesChange = ({country }) => {

    if(!currentCountry || (country && country !== currentCountry.id))
    {
      const i18nCountry = countries.filter((i18n: I18n) => { return i18n.id === country})[0]
      setCurrentCountry(i18nCountry)
      //form.setFieldValue("jamaat", null)
    }
  };

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values);
    const userData: UserDataDto =  loggedInUser;

    //const i18nJamaat = jamaats["jamaats-"+currentCountry.defaultLabel.toLowerCase()].filter((i18n: I18n) => { return i18n.id === values.jamaat})[0]
    setLoading(true)

    message.loading({ content: 'Updating User Loading...', key: userData.id });

    dispatch(updateUserTajneed({finalData: {
        userId: userData.id,
        userData: {...userData, ...values,
          birthdate: values.birthdate,
          country: currentCountry,
          }
      },
      callbackSuccess: (updatedUser: UserDataDto) => {
        console.log(updatedUser)
        dispatch(getLoggedInUser({}));
        setLoading(false)
        message.success({ content: 'User updated!', key: userData.id, duration: 3 });
        setComponentDisabled(true)
      },
      callbackError: (error: any) => {
        console.log(error)
        message.error({ content: 'User update failed: '+error, key: userData.id, duration: 10 });
        //setInitialFormData()
        setLoading(false)
      }}))
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error({ content: 'User update failed: '+errorInfo, duration: 10 });
  };

  const setInitialFormData = () =>  {
    if (form) {
      const userData: UserDataDto = loggedInUser;
      setCurrentCountry(userData?.country)
      form.setFieldsValue({
        ...userData,
        birthdate: userData?.birthdate ? moment.utc(userData.birthdate) : null,
        country: userData?.country?.id,
      })
    }
  }

  useEffect(() => {
    setInitialFormData();

  }, []);
  
  const getAccountStatus = () => {
    const userData : UserDataDto = loggedInUser
       
    switch (userData?.approvalStatus) {
      case "APPROVED":
        return <Tag icon={<CheckCircleOutlined />} color="success">Account active</Tag>
      case "NEW_REGISTERED_ACCOUNT":
        return <Tag icon={<ExclamationCircleOutlined />} color="warning">Please provide your Tajneed Data!</Tag>
      case "APPROVAL_RQUESTED":
        return <Tag icon={<ClockCircleOutlined />} color="processing">Account approval is pending!</Tag>
      case "REJECTED":
        return <Tag icon={<CheckCircleOutlined />} color="error">Account inactive</Tag>
    }
    
  } 
  
  console.log(loggedInUser)
  return (
    <>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="start" align="middle" gutter={[24, 0]}>
            <Col span={24} md={6} className="col-info">
              <Avatar.Group>
                <Avatar size={74}
                    src={loggedInUser?.gender ? loggedInUser?.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : loggedInUser?.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                    shape="square" >{loggedInUser?.gender}</Avatar>

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{loggedInUser?.firstName} {loggedInUser?.lastName}</h4>
                  <span className="ant-statistic-title p">{loggedInUser?.email}</span>
                </div>
              </Avatar.Group>
              
            </Col>
            <Col span={24} md={12}>
              {loggedInUser?.emailVerified ? "" : <Tag icon={<CloseCircleOutlined />} color="error">unverified email!</Tag> }
              {getAccountStatus()}
              {
                loggedInUser?.approvalStatus === "APPROVAL_RQUESTED" ?
                    <>
                      <p className="ant-statistic-title" style={{whiteSpace: "wrap"}}>
                        <br/>Your registration has been successful, Alhamdulillah! <br/><br/>Once it is approved, you will receive an email notification, insha'Allah, and you will then be automatically redirected in the app to write to Hudhur (aba).
                      </p>
                    </>: ""
              }
            </Col>
          </Row>
        }
      ></Card>
      <Spin spinning={isLoading} >
      { loggedInUser?.approvalStatus !== "APPROVAL_RQUESTED" ?<Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          onValuesChange={onFormValuesChange}
          disabled={componentDisabled}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
      >
      <Row gutter={[24, 0]}>
        
        <Col span={24} md={12} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            title={<h6 className="font-semibold m-0">Account Data</h6>}
            //extra={<Button type="link">{pencil}</Button>}
          >
              <Form.Item name="urduName" label="Name in Urdu" rules={[{ required: false, message: 'Name in Urdu is a optional field' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="gender" label="Gender" rules={[{ required: true, message: 'Gender is a mandatory field' }]}>
                <Select>
                  <Select.Option value="MALE">Male</Select.Option>
                  <Select.Option value="FEMALE">Female</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="birthdate" label="Date of Birth" rules={[{ required: true, message: 'Date of Birth is a mandatory field' }]}>
                <DatePicker />
              </Form.Item>
              
              <Form.Item name="aimsId" label="AIMS ID" rules={[{ required: false, message: 'AIMS ID is an optional field' }]}>
                <Input />
              </Form.Item>
            <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Country is a mandatory field' }]}>
              <Select>
                {countries?.map((item: I18n) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.translations.en}
                    </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="jamaat" label="Jamaat" rules={[{ required: false, message: 'Jamaat is an optional field' }]}>
              <Input />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} md={12} className="mb-24">
            <Card
                bordered={false}
                className="header-solid h-full"
                title={<h6 className="font-semibold m-0">Address Data</h6>}
                //extra={<Button type="link">{pencil}</Button>}
            >

            <Form.Item name="contactNumber" label="Contact number" rules={[{ required: true, message: 'Contact number is a mandatory field' }]}>
              <Input />
            </Form.Item> 
            <Form.Item label="Adress Data" required={true}>
              <Input.Group compact>
                <Form.Item name="streetLine1" noStyle label="Streetline 1" rules={[{ required: true, message: 'Street is a mandatory field' }]}>
                  <Input placeholder="Street" />
                </Form.Item>
                <Form.Item name="streetLine2" noStyle label="Streetline 2" rules={[{ required: true, message: 'Street No is a mandatory field' }]}>
                  <Input placeholder="Street No" />
                </Form.Item>
                <Form.Item  name="zipCode" noStyle label="ZIP Code" rules={[{ required: true, message: 'Zip Code is a mandatory field' }]}>
                  <Input placeholder="Zip Code"/>
                </Form.Item>
                <Form.Item name="city" noStyle label="City" rules={[{ required: true, message: 'City is a mandatory field' }]}>
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item name="state" noStyle label="State" rules={[{ required: true, message: 'State is a mandatory field' }]}>
                  <Input placeholder="State" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Card>
        </Col>
        {
            loggedInUser?.approvalStatus === "NEW_REGISTERED_ACCOUNT" ? <Col span={24} md={24} className="mb-24">
              <Card
                  bordered={false}
                  className="header-solid h-full"
                  title={<h6 className="font-semibold m-0">Submit Tajneed Data</h6>}
                  //extra={<Button type="link">{pencil}</Button>}
              >
                <p className="text-dark">
                  {" "}
                  I hereby confirm that I have checked my data for accuracy and that it corresponds to my current address and Jamaat..{" "}
                </p>
                <hr className="my-25"/>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit Data
                  </Button>
                </Form.Item>
              </Card>
            </Col> : ""
        }
        
      </Row>
      </Form> : ""}
      </Spin>
      {/*<Card*/}
      {/*  bordered={false}*/}
      {/*  className="header-solid mb-24"*/}
      {/*  title={*/}
      {/*    <>*/}
      {/*      <h6 className="font-semibold">Projects</h6>*/}
      {/*      <p>Architects design houses</p>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <Row gutter={[24, 24]}>*/}
      {/*    {project.map((p, index) => (*/}
      {/*      <Col span={24} md={12} xl={6} key={index}>*/}
      {/*        <Card*/}
      {/*          bordered={false}*/}
      {/*          className="card-project"*/}
      {/*          cover={<img alt="example" src={p.img} />}*/}
      {/*        >*/}
      {/*          <div className="card-tag">{p.titlesub}</div>*/}
      {/*          <h5>{p.titile}</h5>*/}
      {/*          <p>{p.disciption}</p>*/}
      {/*          <Row gutter={[6, 0]} className="card-footer">*/}
      {/*            <Col span={12}>*/}
      {/*              <Button type="button">VIEW PROJECT</Button>*/}
      {/*            </Col>*/}
      {/*            <Col span={12} className="text-right">*/}
      {/*              <Avatar.Group className="avatar-chips">*/}
      {/*                <Avatar size="small" src={profilavatar} />*/}
      {/*                <Avatar size="small" src={convesionImg} />*/}
      {/*                <Avatar size="small" src={convesionImg2} />*/}
      {/*                <Avatar size="small" src={convesionImg3} />*/}
      {/*              </Avatar.Group>*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*        </Card>*/}
      {/*      </Col>*/}
      {/*    ))}*/}
      {/*    <Col span={24} md={12} xl={6}>*/}
      {/*      <Upload*/}
      {/*        name="avatar"*/}
      {/*        listType="picture-card"*/}
      {/*        className="avatar-uploader projects-uploader"*/}
      {/*        showUploadList={false}*/}
      {/*        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"*/}
      {/*        beforeUpload={beforeUpload}*/}
      {/*        onChange={handleChange}*/}
      {/*      >*/}
      {/*        {imageURL ? (*/}
      {/*          <img src={imageURL} alt="avatar" style={{ width: "100%" }} />*/}
      {/*        ) : (*/}
      {/*          uploadButton*/}
      {/*        )}*/}
      {/*      </Upload>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Card>*/}
    </>
  );
}

export default Profile;
