import {accRestClient} from "../index";


const getItemsByContext = async (data) => {
  const response = await accRestClient.i18nApi().getI18nItemsForContext(data.catalog, data.context, 0, -1)
  return response.data;
};

const getI18nItem = async (data) => {
  const response = await accRestClient.i18nApi().getI18nItem(data.i18nKey)
  return response.data;
};

const updateI18nItem = async (data) => {
  console.log(data)
  const response = await accRestClient.i18nApi().updateI18nItem(data.i18nItem.key, data.i18nItem)
  return response.data;
};

const i18nRestService = {
  getItemsByContext,
  getI18nItem,
  updateI18nItem
};

export default i18nRestService;