import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";
import i18nRestService from "../../services/i18n-rest.service";


const initialState = {
    users: null,
    isUserLoading: false,
    jamaats: {},
    loggedInUser: null,
}

export const getLoggedInUser: any = createAsyncThunk(
    "app/getLoggedInUser",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadMe();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadUsers: any = createAsyncThunk(
    "app/loadUsers",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadUsers();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateUserTajneed: any = createAsyncThunk(
    "app/updateUserTajneed",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.updateUserTajneed(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const approveUserAccounts: any = createAsyncThunk(
    "app/approveUserAccounts",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.approveUserAccounts(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getCountries = createAsyncThunk(
    "i18n/getCountries",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const i18nData = {
                catalog: "core-data",
                context: "countries"
            }
            return await i18nRestService.getItemsByContext(i18nData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getJamaats = createAsyncThunk(
    "i18n/getJamaats",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const i18nData = {
                catalog: "core-data",
                context: finalData.jamaatKey
            }
            return {key: finalData.jamaatKey, data: await i18nRestService.getItemsByContext(i18nData)};
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoggedInUser.pending, (state) => {
                state.isLoggeInUserLoading = true;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.loggedInUser = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadUsers.pending, (state) => {
                state.isUserLoading = true;
            })
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.isUserLoading = false;
                state.users = action.payload;
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.isUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(getCountries.pending, (state) => {
                state.isCountriesLoading = true;
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.isCountriesLoading = false;
                state.countries = action.payload.content;
            })
            .addCase(getCountries.rejected, (state, action) => {
                state.isCountriesLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(getJamaats.pending, (state) => {
                state.isJamaatLoading = true;
            })
            .addCase(getJamaats.fulfilled, (state, action) => {
                state.isJamaatLoading = false;
                state.jamaats[action.payload.key] = action.payload.data.content;
            })
            .addCase(getJamaats.rejected, (state, action) => {
                state.isJamaatLoading = false;
                state.errorPaylod = action.payload;
            })
    },
});

export const {setAppState} = appSlice.actions;

export default appSlice.reducer;