import React, {useEffect, useRef, useState} from 'react';
import {
    Form,
    Input,
    Select,
    DatePicker,
    Checkbox, Button, Spin, message, Tag
} from 'antd';
import {UserDataDto} from "acc-rest-client";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {I18n} from "acc-rest-client";
import {FormProps} from "antd";
import {loadUsers, updateUserTajneed} from "../../store/app/app.slice";
import {CloseCircleOutlined} from "@ant-design/icons";

const EditUser = (props) => {
    const [componentDisabled, setComponentDisabled] = useState(props.user?.approvalStatus !== "NEW_REGISTERED_ACCOUNT" );
    const [currentCountry: I18n, setCurrentCountry] = useState(null);
    const [isLoading: boolean, setLoading] = useState(false);
    const { countries } = useSelector((state) => state.app);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    
    
    
    const onFormValuesChange = ({country }) => {
        
        if(!currentCountry || (country && country !== currentCountry.id))
        {
            const i18nCountry = countries.filter((i18n: I18n) => { return i18n.id === country})[0]
            setCurrentCountry(i18nCountry)
            //form.setFieldValue("jamaat", null)
        }
    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);
        const userData: UserDataDto = props.user ? props.user : {};
        
        //const i18nJamaat = jamaats["jamaats-"+currentCountry.defaultLabel.toLowerCase()].filter((i18n: I18n) => { return i18n.id === values.jamaat})[0]
        setLoading(true)

        message.loading({ content: 'Updating User Loading...', key: userData.id });
        
        dispatch(updateUserTajneed({finalData: {
                                                userId: userData.id,
                                                userData: {...userData, ...values,
                                                birthdate: values.birthdate,
                                                country: currentCountry
                                                }
                                    },
                                    callbackSuccess: (updatedUser: UserDataDto) => {
                                        console.log(updatedUser)
                                        dispatch(loadUsers({}));
                                        setLoading(false)
                                        message.success({ content: 'User updated!', key: userData.id, duration: 3 });
                                        setComponentDisabled(true)
                                    },
                                    callbackError: (error: any) => {
                                        console.log(error)
                                        message.error({ content: 'User update failed: '+error, key: userData.id, duration: 10 });
                                        setInitialFormData()
                                        setLoading(false)
                                    }}))
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error({ content: 'User update failed: '+errorInfo, duration: 10 });
    };
    
    const tailFormItemLayout = {
        wrapperCol: {
            span: 2,
            offset: 6,
        }
    };

    const setInitialFormData = () =>  {
        if (form) {
            const userData: UserDataDto = props.user ? props.user : {};
            setCurrentCountry(userData?.country)
            form.setFieldsValue({
                ...userData,
                birthdate: userData.birthdate ? moment.utc(userData.birthdate) : null,
                country: userData.country?.id,
            })
        }
    }

    useEffect(() => {
        setInitialFormData();
        
    }, []);
    
    return (
        
        <>
            <Spin spinning={isLoading} >
            <Checkbox checked={componentDisabled} onChange={e => setComponentDisabled(e.target.checked)}>
                Tajneed Data (read only))
            </Checkbox>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                onValuesChange={onFormValuesChange}
                disabled={componentDisabled}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'First Name is a mandatory field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Last Name is a mandatory field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="urduName" label="Name in Urdu" rules={[{ required: false, message: 'Name in Urdu is a optional field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="gender" label="Gender" rules={[{ required: true, message: 'Gender is a mandatory field' }]}>
                    <Select>
                        <Select.Option value="MALE">Male</Select.Option>
                        <Select.Option value="FEMALE">Female</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="birthdate" label="Date of Birth" rules={[{ required: true, message: 'Date of Birth is a mandatory field' }]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Country is a mandatory field' }]}>
                    <Select>
                        {countries?.map((item: I18n) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.translations.en}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="jamaat" label="Jamaat" rules={[{ required: false, message: 'Jamaat is an optional field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Adress Data" required={true}>
                <Input.Group compact>
                    <Form.Item name="streetLine1" noStyle label="Streetline 1" rules={[{ required: true, message: 'Street is a mandatory field' }]}>
                        <Input placeholder="Street" />
                    </Form.Item>
                    <Form.Item name="streetLine2" noStyle label="Streetline 2" rules={[{ required: true, message: 'Street No is a mandatory field' }]}>
                        <Input placeholder="Street No" />
                    </Form.Item>
                    <Form.Item  name="zipCode" noStyle label="ZIP Code" rules={[{ required: true, message: 'Zip Code is a mandatory field' }]}>
                        <Input placeholder="Zip Code"/>
                    </Form.Item>
                    <Form.Item name="city" noStyle label="City" rules={[{ required: true, message: 'City is a mandatory field' }]}>
                        <Input placeholder="City" />
                    </Form.Item>
                    <Form.Item name="state" noStyle label="State" rules={[{ required: true, message: 'State is a mandatory field' }]}>
                        <Input placeholder="State" />
                    </Form.Item>
                </Input.Group>
                </Form.Item>
                <Form.Item name="contactNumber" label="Contact number" rules={[{ required: true, message: 'Contact number is a mandatory field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="aimsId" label="AIMS ID" rules={[{ required: false, message: 'AIMS ID is an optional field' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email is a mandatory field' }]}>
                    <Input disabled={true}/>
                </Form.Item>
                {props.user?.emailVerified ?  "" : <Form.Item label="NOTE"><Tag icon={<CloseCircleOutlined />} color="error">unverified email!</Tag></Form.Item> }
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Update User
                    </Button>
                </Form.Item>
            </Form>
            </Spin>
        </>
    );
};

export default EditUser;