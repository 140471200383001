import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography, Alert, Tag, Space, Modal,
} from "antd";

import {DE, US} from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'


import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {approveUserAccounts, loadUsers, updateUserTajneed} from "../store/app/app.slice";
import moment from "moment";
import {UserDataDto, UserDataDtoGenderEnum} from "acc-rest-client";
import EditUser from "../components/dialogs/EditUser";

const {Title} = Typography;

const statusTemplate = (user: UserDataDto) => {
    switch (user.approvalStatus) {
        case "NEW_REGISTERED_ACCOUNT":
            return <div align={"center"}>
                <Tag style={{width: "100%"}} icon={<ExclamationCircleOutlined/>} color="default">NEW</Tag>
                <p style={{
                    fontStyle: "italic",
                    fontSize: "small"
                }}>{moment(user.createdTimestamp).format("DD.MM.yyyy - HH:mm:ss")}</p>
            </div>
        case "APPROVAL_RQUESTED":
            return <div align={"center"}>
                <Tag style={{width: "100%"}} icon={<ClockCircleOutlined/>} color="processing">PENDING</Tag>
                <p style={{
                    fontStyle: "italic",
                    fontSize: "small"
                }}>{moment(user.createdTimestamp).format("DD.MM.yyyy - HH:mm:ss")}</p>
            </div>
        case "APPROVED":
            return <div align={"center"}>
                <Tag style={{width: "100%"}} icon={<CheckCircleOutlined/>} color="success">{user.approvalStatus}</Tag>
                <p style={{
                    fontStyle: "italic",
                    fontSize: "small"
                }}>{moment(user.createdTimestamp).format("DD.MM.yyyy - HH:mm:ss")}</p>
            </div>

        case "REJECTED":
            return <div align={"center"}>
                <Tag style={{width: "100%"}} icon={<MinusCircleOutlined/>} color="error">{user.approvalStatus}</Tag>
                <p style={{
                    fontStyle: "italic",
                    fontSize: "small"
                }}>{moment(user.createdTimestamp).format("DD.MM.yyyy - HH:mm:ss")}</p>
            </div>
        default:

    }
}

const editTemplate = (user: UserDataDto, showModalEditUser) => {
    switch (user.approvalStatus) {
        case "NEW_REGISTERED_ACCOUNT":
            return <><Button style={{width: "100%"}} type="primary" className="tag-primary" onClick={() => showModalEditUser(user)}>
            Update & Approve
                </Button>
            </>
        case "APPROVAL_RQUESTED":
            return <><Button style={{width: "100%"}} type="primary" className="tag-primary" onClick={() => showModalEditUser(user)}>
                Approve
            </Button></>
        case "APPROVED":
            return <><Button style={{width: "100%"}} type="primary" className="tag-primary" onClick={() => showModalEditUser(user)}>
                Edit
            </Button></>
        case "REJECTED":
            return <><Button style={{width: "100%"}} type="primary" className="tag-primary" onClick={() => showModalEditUser(user)}>
                Update & Approve
            </Button></>
        default:

    }
}

function Members() {
    const dispatch = useDispatch();
    const {users} = useSelector((state) => state.app);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentUser: UserDataDto, setCurrentUser] = useState(null);
    const [isLoading: boolean, setLoading] = useState(false);

    useEffect(() => {
        dispatch(loadUsers({}));
    }, []);
    
    const showModalEditUser = (currentUser) => {
        setCurrentUser(currentUser);
        setOpenEditDialog(true);
    };
    
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    
    const hasUserRequestedApproval = () =>  {
        if(currentUser)
        {
            switch (currentUser.approvalStatus){
                case "APPROVAL_RQUESTED":
                    return true;
            }
        }

        return false;
    }
    
    const handleOk = () =>  {
        if(hasUserRequestedApproval())
        {
            setLoading(true)
            message.loading({ content: 'Approving User Account...', key: currentUser.id });
            dispatch(approveUserAccounts({finalData: {
                    users: [currentUser]
                },
                callbackSuccess: (updatedUsers: UserDataDto[]) => {
                    console.log(updatedUsers)
                    dispatch(loadUsers({}));
                    setLoading(false)
                    message.success({ content: 'User Account activated!!', key: currentUser.id, duration: 3 });
                },
                callbackError: (error: any) => {
                    console.log(error)
                    message.error({ content: 'Approving User Account failed: '+error, key: currentUser.id, duration: 10 });
                    setLoading(false)
                }
            }))
        };
    }

    const hideModalEditUser = () =>  {
        setCurrentUser(null);
        setOpenEditDialog(false);
    }

    const columns = [
        {
            id: 1,
            hidden: false,
            title: "Member",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ?

                <Avatar.Group>
                    <div style={{marginRight: "3px"}}>
                        <Avatar
                            src={user.gender ? user.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : user.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                            shape="square" size="large">{user.gender}</Avatar>
                    </div>
                    <div className="avatar-info">
                        <Title
                            level={5}>{user.firstName + " " + user.lastName}</Title>
                        <p>{user.email}</p>
                    </div>
                </Avatar.Group> : "N/A"),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        },
        {
            id: 2,
            hidden: false,
            title: "Jamaat",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ? <div className="author-info">
                <p>{user.aimsId}</p>
                <p>{user.jamaat ? user.jamaat.defaultLabel + " " + getUnicodeFlagIcon("DE") : " - "}</p>
            </div> : "N/A": ""),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        },
        {
            id: 3,
            hidden: false,
            title: "Date of Birth",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ? <div className="author-info">
                <p>{user.birthdate ? moment(user.birthdate).format("DD.MM.yyyy") : " "}</p>
            </div> : "N/A": ""),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        },
        {
            id: 5,
            hidden: false,
            title: "Address",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ? <div className="author-info">
                <p>{user.streetLine1}</p>
                <p>{user.streetLine2}</p>
                <p>{user.zipCode} {user.city} {user.state ? "(" + user.state + ")" : ""}</p>
            </div> : "N/A": ""),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        },
        {
            id: 8,
            hidden: false,
            title: "Status/Created",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ? statusTemplate(user) : "N/A": ""),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        }
        ,
        {
            id: 8,
            hidden: false,
            title: "Action",
            dataIndex: "",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (user) => (user ? editTemplate(user, showModalEditUser) : "N/A": ""),
            //filters: uniqueFilterData(projectTypeFilter),
            //onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
        }
    ];


    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="AMJ Members"
                            extra={
                                <>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">PENDING</Radio.Button>
                                        <Radio.Button value="b">NEW</Radio.Button>
                                        <Radio.Button value="c">ACTIVE</Radio.Button>
                                        <Radio.Button value="d">ALL</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={users}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                open={openEditDialog}
                key={currentUser?.id ? currentUser?.id : Math.random()}
                title="User Approval"
                onOk={handleOk}
                okButtonProps={{disabled: !hasUserRequestedApproval()}}
                okText={"Approve User Account"}
                onCancel={hideModalEditUser}
                //width={1000}
            >
                <EditUser key={currentUser?.id ? currentUser?.id : Math.random()} user={currentUser}/>
            </Modal>
        </>
    );
}

export default Members;
