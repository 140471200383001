import { useAuth } from "react-oidc-context"
import {getAuthenticatedUser} from "../../utils/authHelper";
import {Redirect} from "react-router-dom";
import OIDCLogin from "../../components/login/OIDCLogin";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

function ProtectedRoute({ element }) {
  const auth = useAuth();
  const { loggedInUser} = useSelector((state) => state.app); 
  
  if(!auth.isAuthenticated || !getAuthenticatedUser().isAccountVerified() || !getAuthenticatedUser().isAdmin()) 
  {
    //return element
    if(loggedInUser?.approvalStatus === "NEW_REGISTERED_ACCOUNT" || loggedInUser?.approvalStatus === "APPROVAL_REQUESTED")
    {
      return  <Redirect to={"/profile"}/>
    }else {
      return  <Redirect to={"/error"}/>
    }
  }
  else{
    return element     
  }
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
